import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
  collectCurrentUser,
  collectListingsByIntegerId,
  CollectListingType,
  collectInvoice,
} from "@/gql";

export const useIdParam = () => {
  const { invoiceId: invoiceIdParam, listingId: listingIdParam } = useParams();
  const id = invoiceIdParam ?? listingIdParam;
  const isIntegerId = /^\d+$/.test(id ?? "");

  // The URL param should be an integer, either a listing ID or an invoice ID.
  // If invalid, we rely on the error responses from the queries below.

  // If a listing ID was found in the URL, convert to UUID.
  const {
    data: listingIdData,
    error: listingIdError,
    loading: listingIdLoading,
  } = useQuery(collectListingsByIntegerId, {
    skip: !listingIdParam || !isIntegerId,
    variables: {
      listingIds: [{ id: Number(id), type: CollectListingType.FixedPrice }],
    },
  });
  const listingUuid = listingIdParam
    ? isIntegerId
      ? listingIdData?.collectListingsByIntegerId[0]?.id
      : id
    : undefined;

  // If an invoice ID was found in the URL, convert to UUID.
  const {
    data: userData,
    error: userError,
    loading: userLoading,
  } = useQuery(collectCurrentUser, {
    skip: !invoiceIdParam || !isIntegerId,
  });
  const invoiceUuid = invoiceIdParam
    ? isIntegerId
      ? userData?.collectCurrentUserV2?.collectInvoices?.edges?.find(
          (item) => item?.node?.integerId === Number(id),
        )?.node?.id
      : id
    : undefined;

  // If an invoice ID was not an integer, treat it as a UUID and try to fetch
  // the integer ID.
  const {
    data: invoiceIdData,
    error: invoiceIdError,
    loading: invoiceIdLoading,
  } = useQuery(collectInvoice, {
    skip: !invoiceIdParam || isIntegerId,
    variables: { collectInvoiceId: id },
  });
  const integerId = invoiceIdParam
    ? isIntegerId
      ? id
      : invoiceIdData?.collectInvoice?.integerId
    : undefined;

  return {
    integerId,
    invoiceUuid,
    listingUuid,
    error: userError ?? listingIdError ?? invoiceIdError,
    loading: userLoading || listingIdLoading || invoiceIdLoading,
  };
};
