import { IconProps, Icon } from "@chakra-ui/react";
import { EColor } from "@fanatics-live/common-components";
export const WalletIcon = (props: IconProps) => (
  <Icon viewBox="0 0 36 24" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
    >
      <rect
        height="23"
        rx="3.5"
        stroke={EColor.Neutral45}
        width="35"
        x="0.5"
        y="0.5"
      />
      <path
        d="M24.4167 19.3337H11.5833C10.5708 19.3337 9.75 18.5128 9.75 17.5003V9.25033C9.75 8.2378 10.5708 7.41699 11.5833 7.41699H24.4167C25.4292 7.41699 26.25 8.2378 26.25 9.25033V17.5003C26.25 18.5128 25.4292 19.3337 24.4167 19.3337Z"
        stroke="#282828"
        strokeWidth="1.83333"
      />
      <path
        d="M22.1247 13.8337C21.8715 13.8337 21.6663 13.6285 21.6663 13.3753C21.6663 13.1222 21.8715 12.917 22.1247 12.917C22.3778 12.917 22.583 13.1222 22.583 13.3753C22.583 13.6285 22.3778 13.8337 22.1247 13.8337Z"
        fill="#282828"
        stroke="#282828"
        strokeWidth="1.83333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 7.41667V6.13629C23.5 4.93173 22.3582 4.05449 21.1943 4.36486L11.111 7.05375C10.3084 7.26776 9.75 7.99458 9.75 8.82518V9.25"
        stroke="#282828"
        strokeWidth="1.83333"
      />
    </svg>
    ;
  </Icon>
);
