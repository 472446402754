import { useMutation } from "@apollo/client";

import {
  collectCheckoutInvoiceConfirm,
  CollectCheckoutInvoiceConfirmInput,
} from "@/gql";

// Submits the payment to our service, which will update our records and
// complete the Stripe payment if required.
export const useCompletePayment = () => {
  const [confirmInvoicePayment] = useMutation(collectCheckoutInvoiceConfirm);

  return async (input: CollectCheckoutInvoiceConfirmInput) => {
    const { data: confirmInvoiceData, errors: confirmInvoiceErrors } =
      await confirmInvoicePayment({
        variables: { input },
      });

    const { result, messages } =
      confirmInvoiceData?.collectCheckoutInvoiceConfirm ?? {};
    const errorMessage =
      confirmInvoiceErrors?.[0].message || messages?.[0]?.message || undefined;

    if (errorMessage) {
      const error = new Error(errorMessage);

      error.cause = {
        ...(typeof error.cause === "object" && error.cause !== null
          ? error.cause
          : {}),
        input,
      };

      throw error;
    }

    return result;
  };
};
