import { Text } from "@chakra-ui/react";
import { ETextVariant } from "@fanatics-live/common-components";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { ApplePayIcon, GooglePayIcon, WireTransferIcon } from "@/components";
import { CollectValidPaymentMethod } from "@/gql";

type TAdditionalPaymentMethod =
  | CollectValidPaymentMethod.ApplePay
  | CollectValidPaymentMethod.GooglePay
  | CollectValidPaymentMethod.Wire;

export const isAdditionalPaymentMethod = (
  paymentMethod: CollectValidPaymentMethod,
): paymentMethod is TAdditionalPaymentMethod =>
  [
    CollectValidPaymentMethod.ApplePay,
    CollectValidPaymentMethod.GooglePay,
    CollectValidPaymentMethod.Wire,
  ].includes(paymentMethod);

const getMethodConfig = (
  paymentMethod: TAdditionalPaymentMethod,
  t: TFunction,
) => {
  const sizes = { width: "36px", height: "24px" };

  switch (paymentMethod) {
    case CollectValidPaymentMethod.ApplePay:
      return {
        icon: <ApplePayIcon {...sizes} />,
        text: t("order_details.apple_pay.title"),
      };
    case CollectValidPaymentMethod.GooglePay:
      return {
        icon: <GooglePayIcon {...sizes} />,
        text: t("order_details.google_pay.title"),
      };
    case CollectValidPaymentMethod.Wire:
      return {
        icon: <WireTransferIcon {...sizes} />,
        text: t("cart.payment.wire.title"),
      };
  }
};

export const AdditionalPaymentMethod = ({
  paymentMethod,
}: {
  paymentMethod: TAdditionalPaymentMethod;
}) => {
  const { t } = useTranslation();
  const config = getMethodConfig(paymentMethod, t);

  return (
    <>
      {config?.icon}

      <Text variant={ETextVariant.BaseBold}>{config?.text}</Text>
    </>
  );
};
