import { Text } from "@chakra-ui/react";
import {
  BankAccountIcon,
  CardAmexIcon,
  CardDiscoverIcon,
  CardMastercardIcon,
  CardVisaIcon,
  ECardBrand,
  EColor,
  EPaymentType,
  ETextVariant,
  IPaymentMethod,
} from "@fanatics-live/common-components";
import { useTranslation } from "react-i18next";

import { CreditCardIcon } from "@/components";

import { isExpiredCard } from "../../utils";

const getPaymentMethodConfig = (method?: IPaymentMethod) => {
  const sizes = { width: "36px", height: "24px" };

  const cardIconMap = {
    [ECardBrand.VISA]: <CardVisaIcon {...sizes} />,
    [ECardBrand.MASTERCARD]: <CardMastercardIcon {...sizes} />,
    [ECardBrand.AMEX]: <CardAmexIcon {...sizes} />,
    [ECardBrand.DISCOVER]: <CardDiscoverIcon {...sizes} />,
    [ECardBrand.UNKNOWN]: <CreditCardIcon {...sizes} />,
  };

  if (
    method?.type === EPaymentType.CARD ||
    method?.type === EPaymentType.BANK_ACCOUNT
  ) {
    const paymentTypeMap = {
      [EPaymentType.CARD]: {
        icon: (method.card?.brand && cardIconMap[method.card.brand]) ?? (
          <CreditCardIcon {...sizes} />
        ),
        name: "",
        last4: method.card?.last4,
        isExpired:
          method.card &&
          isExpiredCard(method.card.exp_month, method.card.exp_year),
      },
      [EPaymentType.BANK_ACCOUNT]: {
        icon: <BankAccountIcon {...sizes} width={sizes.height} />,
        name: method.us_bank_account?.bank_name,
        last4: method.us_bank_account?.last4,
        isExpired: undefined,
      },
    };

    return paymentTypeMap[method.type];
  }
};

export const SavedPaymentMethod = ({
  paymentMethodData,
}: {
  paymentMethodData?: IPaymentMethod;
}) => {
  const { t } = useTranslation();
  const paymentMethodConfig = getPaymentMethodConfig(paymentMethodData);

  return (
    paymentMethodConfig && (
      <>
        {paymentMethodConfig.icon}

        {paymentMethodConfig.name && (
          <Text
            overflow="hidden"
            sx={{ textWrap: "nowrap" }}
            textOverflow="ellipsis"
            textTransform="capitalize"
            variant={ETextVariant.BaseBold}
          >
            {paymentMethodConfig.name.toLowerCase()}
          </Text>
        )}

        <Text variant={ETextVariant.BaseBold}>
          ••••{paymentMethodConfig.last4}
        </Text>

        {paymentMethodConfig.isExpired && (
          <Text
            backgroundColor={EColor.BrandDangerLight2}
            borderRadius="31px"
            color={EColor.White}
            fontSize="10px"
            fontWeight={600}
            letterSpacing="0.1px"
            lineHeight="130%"
            mr="5px"
            p="0 4px"
          >
            {t("cart.payment.expired")}
          </Text>
        )}
      </>
    )
  );
};
