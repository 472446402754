import { useQuery } from "@apollo/client";
import {
  IAuth,
  Navigation,
  useIsMobile,
  FanaticsCollectLogoFull,
} from "@fanatics-live/common-components";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  MONOLITH_URL,
  PAST_SEARCH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SEARCH_URL,
} from "@/env";
import { collectCurrentUser } from "@/gql";
import { identify } from "@/utils/tracking";

import "@/globals.css";

interface INavigationProvider {
  children: ReactNode;
  authState: IAuth;
  isInitializing: boolean;
  refetchProfile: () => void;
}

export const Layout = ({
  children,
  authState,
  isInitializing,
}: INavigationProvider) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { data, loading } = useQuery(collectCurrentUser);
  const [identifyInitialized, setIndentifyInitialized] = useState(false);

  if (!loading && data && !identifyInitialized) {
    const { tenantId, email } = data?.collectCurrentUserV2 ?? {};
    identify(tenantId, { email: email?.address ?? "" });
    setIndentifyInitialized(true);
  }

  const isPaymentPage =
    pathname.split("/").filter((s) => s !== "").length === 2;

  return isPaymentPage ? (
    <div className="min-h-screen bg-neutral-0">
      <div className="container max-w-[1144px] md:px-4">
        <div className="border-b border-neutral-200 pb-[19px] pl-2 pt-6 md:border-none md:pl-0 md:pt-[28px]">
          <FanaticsCollectLogoFull
            width={isMobile ? "100px" : "166px"}
            height={isMobile ? "24px" : "40px"}
            className="absolute top-[22px] mr-[3px] md:mr-2"
          />
          <h1 className="text-center text-headline-xl md:text-headline-3xl">
            {t("common.header_checkout")}
          </h1>
        </div>

        {children}
      </div>
    </div>
  ) : (
    <Navigation
      auth={authState}
      fanaticsCollectBaseUrl={MONOLITH_URL}
      fanIdBaseUrl={FAN_ID_BASE_URL ?? ""}
      fanIdClientId={FAN_ID_CLIENT_ID ?? ""}
      fanIdPreview={FAN_ID_PREVIEW}
      searchBaseUrl={SEARCH_URL}
      pastSearchBaseUrl={PAST_SEARCH_URL}
      showNonProdBanner={true}
      isInitializing={isInitializing}
    >
      {children}
    </Navigation>
  );
};
