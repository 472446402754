import {
  CollectCheckoutDetails,
  CollectInvoiceStatus,
  CollectValidPaymentMethod,
  Money,
} from "@/gql";

export const toDollars = (money?: Money | null) =>
  (money?.amountInCents ?? 0) / 100;

export const isInvoicePaid = (details: CollectCheckoutDetails) =>
  [
    CollectInvoiceStatus.StatusPaid,
    CollectInvoiceStatus.StatusPaymentPending,
  ].includes(details.status);

export const isSavedPaymentMethod = (
  paymentMethod?: CollectValidPaymentMethod,
) =>
  paymentMethod &&
  [CollectValidPaymentMethod.Ach, CollectValidPaymentMethod.Card].includes(
    paymentMethod,
  );

export const isExpiredCard = (expMonth: number, expYear: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  return (
    expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth)
  );
};

export const hasCreditCardFee = (paymentMethod?: CollectValidPaymentMethod) =>
  !!paymentMethod &&
  [
    CollectValidPaymentMethod.Card,
    CollectValidPaymentMethod.ApplePay,
    CollectValidPaymentMethod.GooglePay,
  ].includes(paymentMethod);

export const getBalanceRemaining = (
  paymentMethod?: CollectValidPaymentMethod,
  balanceDue?: Money,
  potentialBalanceDueWithCreditCardFee?: Money,
) =>
  hasCreditCardFee(paymentMethod)
    ? potentialBalanceDueWithCreditCardFee
    : balanceDue;

export const isBalanceCoveredByFunds = (
  paymentMethod?: CollectValidPaymentMethod,
  accountFundsToUse?: Money,
  potentialTotalWithCreditCardFee?: Money,
  total?: Money,
) =>
  !!accountFundsToUse &&
  accountFundsToUse.amountInCents >=
    (getBalanceRemaining(paymentMethod, total, potentialTotalWithCreditCardFee)
      ?.amountInCents ?? 0);
