import { Flex, TextProps, Text } from "@chakra-ui/react";
import {
  CautionThinIcon,
  ETextVariant,
} from "@fanatics-live/common-components";

export const ErrorMessage = ({
  message,
  ...restProps
}: { message: string } & TextProps) => {
  return (
    <Flex alignItems="center" gap="10px" pb="12px" pt="16px">
      <CautionThinIcon />
      <Text variant={ETextVariant.Paragraph5} {...restProps}>
        {message}
      </Text>
    </Flex>
  );
};
