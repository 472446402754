import { Flex, Text, Box } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import styles from "@/legacy/styles";

import { ConfirmSectionContent } from "./ConfirmSectionContent";

export const ConfirmSection = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      {...styles.sectionBox}
      pb="12px"
      position={{ base: "static", md: "sticky" }}
      top="70px"
    >
      <Box
        display={{ base: "none", md: "block" }}
        borderColor={EColor.Neutral15}
        borderBottomWidth="1px"
        pb="16px"
        mb="5px"
      >
        <Text
          variant={ETextVariant.Paragraph6}
          fontWeight={600}
          color={EColor.BrandDark}
        >
          {t("cart.confirm.title")}
        </Text>
      </Box>
      <ConfirmSectionContent />
      {children}
    </Flex>
  );
};
