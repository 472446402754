import { t } from "i18next";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { CheckoutError } from "@/components";
import { lazyImport } from "@/utils";

const { Cart } = lazyImport(() => import("@/components/Cart"), "Cart");
const { Details } = lazyImport(() => import("@/legacy/details"), "Details");
const { Invoices } = lazyImport(() => import("@/legacy/invoices"), "Invoices");

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={"/invoices"} />,
  },
  {
    path: "invoices",
    element: <Invoices />,
  },
  {
    path: "invoices/:invoiceId",
    element: <Cart />,
  },
  {
    path: "invoices/:invoiceId/details",
    element: <Details />,
  },
  {
    path: "cart/:listingId",
    element: <Cart />,
  },
  {
    path: "*",
    element: (
      <CheckoutError message={t("errors.page_not_found")} statusCode={404} />
    ),
  },
];

export const AppRoutes = () => useRoutes(routes);
