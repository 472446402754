import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { CollectLineItem, Money } from "@/gql";
import { useCheckoutDetails, useIdParam, useCartContext } from "@/hooks";
import styles from "@/legacy/styles";
import { formatMonetaryValue, toDollars } from "@/utils";

import { isTradeShowInvoice } from "./ShippingSection/utils";

const flattenLineItems = (
  lineItems: CollectLineItem[] = [],
): {
  amount: Money;
  description: string;
  images?: string[];
  title: string;
}[] => {
  const fees = lineItems
    .filter((item) => !item.listing)
    .map((item) => ({
      amount: item.amount,
      description: item.description || "",
      title: item.title || "",
    }));

  const listings = lineItems
    .filter(
      // Auction listings have two line items: one for the current bid and one
      // for the buyer's premium. Buyer's premium items already contain the
      // listing with bid amount. Buy Now listings have a current bid of 0.
      (item) =>
        item.listing &&
        item.amount.amountInCents !== item.listing?.currentBid?.amountInCents,
    )
    .map((item) => ({
      amount: {
        ...item.amount,
        amountInCents:
          (item.amount.amountInCents ?? 0) +
          (item.listing?.currentBid?.amountInCents ?? 0),
      },
      description: item.listing?.subtitle || "",
      images: item.listing?.images,
      title: item.listing?.title || "",
    }));

  return [...listings, ...fees];
};

export const ItemSection = () => {
  const { t } = useTranslation();
  const { detailsOptions } = useCartContext();
  const { details } = useCheckoutDetails(detailsOptions);
  const { integerId } = useIdParam();

  const items = flattenLineItems(details?.lineItems as CollectLineItem[]);

  return (
    <Box
      {...styles.sectionBox}
      borderBottomWidth={{ base: 0, md: "1px" }}
      pb="8px"
    >
      <Text {...styles.h2}>
        {integerId
          ? t("cart.invoice.title", { id: integerId })
          : t("cart.invoice.items", { count: items.length })}
      </Text>

      {details ? (
        <>
          <Box mb="12px">
            <Text {...styles.p}>
              {DateTime.fromISO(details?.invoiceDate).toLocaleString(
                DateTime.DATE_FULL,
              )}
            </Text>
            {detailsOptions.invoiceId && (
              <Text {...styles.p}>
                {isTradeShowInvoice(details)
                  ? t("cart.invoice.trade_show")
                  : t("cart.invoice.items", { count: items.length })}
              </Text>
            )}
          </Box>

          {items.map((item, i) => (
            <Flex
              alignItems="center"
              borderTop={i === 0 ? `1px solid ${EColor.Neutral15}` : "none"}
              borderBottom={`1px solid ${EColor.Neutral15}`}
              gap="16px"
              justifyContent="space-between"
              key={i}
            >
              <Box
                alignSelf="stretch"
                bg={EColor.Neutral2}
                display="flex"
                flex="0 0 20%"
                p="16px"
              >
                {item.images?.length && (
                  <Box alignSelf="center">
                    <img alt={item.title} src={item.images[0]} />
                  </Box>
                )}
              </Box>

              <Box flex="1" p="16px 0">
                <Text
                  {...styles.twoLineEllipses}
                  mb="4px"
                  variant={ETextVariant.BaseBold}
                >
                  {item.title}
                </Text>
                <Text
                  {...styles.twoLineEllipses}
                  variant={ETextVariant.SmallMono}
                  color={EColor.Neutral55}
                  textTransform="uppercase"
                  whiteSpace="pre-line"
                >
                  {item.description}
                </Text>
              </Box>

              <Text p="16px" variant={ETextVariant.BaseBold}>
                {formatMonetaryValue(toDollars(item.amount))}
              </Text>
            </Flex>
          ))}

          <Text
            variant={ETextVariant.BaseParagraph}
            textAlign="right"
            my="16px"
          >
            {t("cart.invoice.subtotal", { count: items.length })}{" "}
            <Text as="span" variant={ETextVariant.BaseBold}>
              {formatMonetaryValue(toDollars(details?.subTotal))}
            </Text>
          </Text>
        </>
      ) : (
        <Box textAlign="center" pb="8px" ml="16px">
          <Spinner />
        </Box>
      )}
    </Box>
  );
};
