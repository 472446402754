import { Icon, IconProps } from "@chakra-ui/react";

export const GooglePayIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 58 30" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="58"
        height="30"
        viewBox="0 0 58 30"
        fill="none"
      >
        <path
          d="M41.9115 0H15.1853C6.83339 0 0 6.75 0 15C0 23.25 6.83339 30 15.1853 30H41.9115C50.2634 30 57.0968 23.25 57.0968 15C57.0968 6.75 50.2634 0 41.9115 0Z"
          fill="white"
        />
        <path
          d="M41.9115 0H15.1853C6.83339 0 0 6.75 0 15C0 23.25 6.83339 30 15.1853 30H41.9115C50.2634 30 57.0968 23.25 57.0968 15C57.0968 6.75 50.2634 0 41.9115 0Z"
          fill="white"
        />
        <path
          d="M41.9115 1.215C43.7868 1.215 45.6091 1.5825 47.325 2.3025C48.9878 3 50.476 3.9975 51.7667 5.265C53.0499 6.5325 54.0597 8.01 54.7658 9.6525C55.4947 11.3475 55.8668 13.1475 55.8668 15C55.8668 16.8525 55.4947 18.6525 54.7658 20.3475C54.0597 21.99 53.0499 23.46 51.7667 24.735C50.4836 26.0025 48.9878 27 47.325 27.6975C45.6091 28.4175 43.7868 28.785 41.9115 28.785H15.1853C13.3099 28.785 11.4877 28.4175 9.77175 27.6975C8.10896 27 6.6208 26.0025 5.33004 24.735C4.04689 23.4675 3.03706 21.99 2.33095 20.3475C1.60205 18.6525 1.23001 16.8525 1.23001 15C1.23001 13.1475 1.60205 11.3475 2.33095 9.6525C3.03706 8.01 4.04689 6.54 5.33004 5.265C6.6132 3.9975 8.10896 3 9.77175 2.3025C11.4877 1.5825 13.3099 1.215 15.1853 1.215H41.9115ZM41.9115 0H15.1853C6.83339 0 0 6.75 0 15C0 23.25 6.83339 30 15.1853 30H41.9115C50.2634 30 57.0968 23.25 57.0968 15C57.0968 6.75 50.2634 0 41.9115 0Z"
          fill="#3C4043"
        />
        <path
          d="M27.2268 16.0659V20.6034H25.769V9.39844H29.6337C30.6132 9.39844 31.4484 9.72094 32.1317 10.3659C32.8302 11.0109 33.1795 11.7984 33.1795 12.7284C33.1795 13.6809 32.8302 14.4684 32.1317 15.1059C31.4559 15.7434 30.6207 16.0584 29.6337 16.0584H27.2268V16.0659ZM27.2268 10.7784V14.6859H29.6641C30.2411 14.6859 30.727 14.4909 31.1067 14.1084C31.4939 13.7259 31.6913 13.2609 31.6913 12.7359C31.6913 12.2184 31.4939 11.7609 31.1067 11.3784C30.727 10.9809 30.2487 10.7859 29.6641 10.7859H27.2268V10.7784Z"
          fill="#3C4043"
        />
        <path
          d="M36.9911 12.6836C38.0692 12.6836 38.9196 12.9686 39.5422 13.5386C40.1648 14.1086 40.4761 14.8886 40.4761 15.8786V20.6036H39.0866V19.5386H39.0259C38.4261 20.4161 37.6213 20.8511 36.619 20.8511C35.7611 20.8511 35.0473 20.6036 34.4703 20.1011C33.8933 19.5986 33.6047 18.9761 33.6047 18.2261C33.6047 17.4311 33.9084 16.8011 34.5159 16.3361C35.1233 15.8636 35.9357 15.6311 36.9455 15.6311C37.8111 15.6311 38.5248 15.7886 39.079 16.1036V15.7736C39.079 15.2711 38.8816 14.8511 38.4792 14.4986C38.0768 14.1461 37.6061 13.9736 37.067 13.9736C36.2546 13.9736 35.6092 14.3111 35.1385 14.9936L33.8553 14.1986C34.5614 13.1861 35.6092 12.6836 36.9911 12.6836ZM35.1081 18.2486C35.1081 18.6236 35.2675 18.9386 35.594 19.1861C35.9129 19.4336 36.2925 19.5611 36.7253 19.5611C37.3403 19.5611 37.887 19.3361 38.3653 18.8861C38.8437 18.4361 39.0866 17.9111 39.0866 17.3036C38.6311 16.9511 38.0009 16.7711 37.1885 16.7711C36.5962 16.7711 36.1027 16.9136 35.7079 17.1911C35.3055 17.4836 35.1081 17.8361 35.1081 18.2486Z"
          fill="#3C4043"
        />
        <path
          d="M48.4028 12.9316L43.5435 23.9716H42.0401L43.8472 20.1091L40.6431 12.9316H42.2299L44.5381 18.4366H44.5685L46.8159 12.9316H48.4028Z"
          fill="#3C4043"
        />
        <path
          d="M21.4293 15.1535C21.4293 14.684 21.3868 14.2347 21.3078 13.8027H15.1973V16.2777L18.7165 16.2785C18.5737 17.102 18.1144 17.804 17.4105 18.272V19.8777H19.5053C20.7285 18.7595 21.4293 17.1065 21.4293 15.1535Z"
          fill="#4285F4"
        />
        <path
          d="M17.4104 18.2706C16.8273 18.6591 16.0764 18.8864 15.1979 18.8864C13.501 18.8864 12.0614 17.7569 11.5459 16.2344H9.38501V17.8904C10.4556 19.9889 12.6559 21.4289 15.1979 21.4289C16.9549 21.4289 18.4309 20.8581 19.5053 19.8756L17.4104 18.2706Z"
          fill="#34A853"
        />
        <path
          d="M11.3429 15.0063C11.3429 14.5788 11.4151 14.1656 11.5464 13.7771V12.1211H9.38554C8.94289 12.9888 8.69385 13.9683 8.69385 15.0063C8.69385 16.0443 8.94364 17.0238 9.38554 17.8916L11.5464 16.2356C11.4151 15.8471 11.3429 15.4338 11.3429 15.0063Z"
          fill="#FABB05"
        />
        <path
          d="M15.1979 11.1245C16.1569 11.1245 17.0156 11.4508 17.6937 12.0883L19.5501 10.256C18.4225 9.21878 16.9526 8.58203 15.1979 8.58203C12.6567 8.58203 10.4556 10.022 9.38501 12.1205L11.5459 13.7765C12.0614 12.254 13.501 11.1245 15.1979 11.1245Z"
          fill="#E94235"
        />
      </svg>
    </Icon>
  );
};
