import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import en from "@/locales/en";

export const defaultNS = "common";

export const resources = {
  en: {
    [defaultNS]: en,
  },
} as const;

use(initReactI18next).init({
  resources,
  lng: "en",
  ns: [defaultNS],
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
});
