import { Icon, IconProps } from "@chakra-ui/react";

export const GoogleIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 19 20" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
      >
        <path
          d="M18.6024 10.2112C18.6024 9.52546 18.5403 8.86927 18.4249 8.23828H9.49963V11.8533L14.6399 11.8544C14.4314 13.0573 13.7604 14.0826 12.7324 14.7662V17.1116H15.7921C17.5787 15.4783 18.6024 13.0638 18.6024 10.2112Z"
          fill="#4285F4"
        />
        <path
          d="M12.7318 14.7652C11.8801 15.3327 10.7833 15.6646 9.5002 15.6646C7.02157 15.6646 4.91889 14.0148 4.16587 11.791H1.00964V14.2098C2.57334 17.275 5.78724 19.3783 9.5002 19.3783C12.0664 19.3783 14.2223 18.5446 15.7916 17.1095L12.7318 14.7652Z"
          fill="#34A853"
        />
        <path
          d="M3.86932 9.99553C3.86932 9.37111 3.97468 8.76751 4.16653 8.20006V5.78125H1.0103C0.363752 7.04871 0 8.4794 0 9.99553C0 11.5117 0.364861 12.9424 1.0103 14.2098L4.16653 11.791C3.97468 11.2236 3.86932 10.62 3.86932 9.99553Z"
          fill="#FABB05"
        />
        <path
          d="M9.5002 4.32693C10.9009 4.32693 12.1552 4.80346 13.1455 5.73462L15.857 3.05838C14.2101 1.54334 12.0631 0.613281 9.5002 0.613281C5.78835 0.613281 2.57334 2.71659 1.00964 5.78172L4.16587 8.20053C4.91889 5.97672 7.02157 4.32693 9.5002 4.32693Z"
          fill="#E94235"
        />
      </svg>
    </Icon>
  );
};
