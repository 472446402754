import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { PropsWithChildren, useEffect, useState } from "react";

import { initialize } from "./initialize";

const SPLIT_TREATMENTS = [
  "Datadog_Browser_Logs_Session_Sample_Rate",
  "Datadog_RUM_Session_Replay_Sample_Rate",
  "Datadog_RUM_Session_Sample_Rate",
];

export const DatadogProvider = ({ children }: PropsWithChildren) => {
  const { treatments, isReady } = useSplitTreatments({
    names: SPLIT_TREATMENTS,
  });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isReady && !isInitialized) {
      const {
        Datadog_Browser_Logs_Session_Sample_Rate,
        Datadog_RUM_Session_Sample_Rate,
        Datadog_RUM_Session_Replay_Sample_Rate,
      } = treatments;

      initialize(
        Number(Datadog_Browser_Logs_Session_Sample_Rate.treatment) || 0,
        Number(Datadog_RUM_Session_Sample_Rate.treatment) || 0,
        Number(Datadog_RUM_Session_Replay_Sample_Rate.treatment) || 0,
      );

      setIsInitialized(true);
    }
  }, [isInitialized, isReady, treatments]);

  return children;
};
