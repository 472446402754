export const groupBy = <T, K extends string>(
  arr: T[],
  predicate: (item: T) => K,
) => {
  return arr.reduce(
    (acc, curr) => {
      (acc[predicate(curr)] ||= []).push(curr);

      return acc;
    },
    {} as Record<K, T[]>,
  );
};
