import { gql } from "./artifacts";

//   variables: {
//     listingIds: [{
//       id: Number(listingId),
//       type: CollectListingType.FixedPrice
//     }]
//   }

export const collectListingsByIntegerId = gql(`
  query CollectListingsByIntegerId($listingIds: [CollectListingIntegerIdInput]!) {
    collectListingsByIntegerId(listingIds: $listingIds) {
      id
    }
  }
`);
