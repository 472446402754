import { StackProps, Text, VStack, TextProps } from "@chakra-ui/react";
import { ETextVariant, countries } from "@fanatics-live/common-components";

import { CollectAddress, CollectShippingAddress } from "@/gql";
import { joinOptionalStrings } from "@/utils";

interface ITextProps
  extends Omit<CollectShippingAddress, "__typename">,
    StackProps {
  addressLine1?: CollectAddress["addressLine1"];
  addressLine2?: CollectAddress["addressLine2"];
  countryName?: CollectAddress["countryName"];
  title?: string;
  titleWeight?: TextProps["fontWeight"];
  textProps?: TextProps;
}

const getCountryById = (countryId: number) =>
  countries.find((option) => option.id === countryId)?.label;

export const AddressText = ({
  title,
  name,
  addressLine1,
  addressLine2,
  street1,
  street2,
  city,
  state,
  zip,
  countryId,
  countryName,
  phone,
  textProps,
  ...restProps
}: ITextProps) => {
  const globalAddress = joinOptionalStrings(
    ", ",
    city ?? "",
    joinOptionalStrings(" ", state ?? "", zip ?? ""),
  );
  const country =
    (countryId ? getCountryById(countryId) : undefined) ?? countryName;

  return (
    <VStack spacing={0} alignItems={"left"} {...restProps}>
      {title ?? name ? (
        <Text variant={ETextVariant.Heading3} {...textProps}>
          {title ?? name}
        </Text>
      ) : null}
      {street1 ?? addressLine1 ? (
        <Text variant={ETextVariant.BodyX} {...textProps}>
          {street1 ?? addressLine1}
        </Text>
      ) : null}
      {street2 ?? addressLine2 ? (
        <Text variant={ETextVariant.BodyX} {...textProps}>
          {street2 ?? addressLine2}
        </Text>
      ) : null}
      {globalAddress ? (
        <Text variant={ETextVariant.BodyX} {...textProps}>
          {globalAddress}
        </Text>
      ) : null}
      {country ? (
        <Text variant={ETextVariant.BodyX} {...textProps}>
          {country}
        </Text>
      ) : null}
      {phone ? (
        <Text variant={ETextVariant.BodyX} {...textProps}>
          {phone}
        </Text>
      ) : null}
    </VStack>
  );
};
