import "@/locales/i18n";
import {
  useFaviconLink,
  ApiProvider,
  AuthProvider,
  Loader,
  ModalProvider,
  useFetchProfile,
} from "@fanatics-live/common-components";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { StrictMode, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import Zendesk from "react-zendesk";

import { CheckoutError, Layout } from "@/components";
import {
  GTM_ID,
  ZENDESK_KEY,
  API_BASE_URL,
  GRAPHQL_API_URL,
  MEMBERS_API_URL,
  MONOLITH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SPLIT_CLIENT_KEY,
  STRIPE_MARKETPLACE_PUBLISHABLE_KEY,
  BUILD_VERSION,
} from "@/env";
import { DatadogProvider, QueryProvider, ThemeProvider } from "@/providers";
import { getFaviconPath, apiClient, auth } from "@/utils";

import { AppRoutes } from "./AppRoutes";

// eslint-disable-next-line no-console
console.log(`Build: ${BUILD_VERSION}`);

// Deprecated: Switching to Segment, remove with rewrite
if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

export const App = () => {
  const faviconPath = getFaviconPath();
  useFaviconLink(faviconPath);

  const navigateToLogin = () => {
    if (process.env.NODE_ENV === "production") {
      window.location.href = `${MONOLITH_URL}/?l`;
    }
  };

  const { authState, isInitializing, refetchProfile } = useFetchProfile(
    auth,
    apiClient,
    navigateToLogin,
  );

  const splitConfig = {
    core: {
      authorizationKey: SPLIT_CLIENT_KEY as string,
      key: authState.profile?.userUuid || "anonymous",
    },
  };

  return (
    <StrictMode>
      <BrowserRouter>
        <ThemeProvider>
          <SplitFactoryProvider config={splitConfig}>
            <DatadogProvider>
              <ErrorBoundary
                FallbackComponent={() => <CheckoutError height="100vh" />}
              >
                <AuthProvider value={authState}>
                  <ApiProvider value={apiClient}>
                    <QueryProvider>
                      <ModalProvider
                        membersApiUrl={MEMBERS_API_URL}
                        fanaticsCollectBaseUrl={MONOLITH_URL}
                        checkoutApiUrl={API_BASE_URL}
                        stripeMarketplaceKey={
                          STRIPE_MARKETPLACE_PUBLISHABLE_KEY ?? ""
                        }
                        addPaymentMethodLoginFlow={false} // TODO: use feature flag from split.io
                        graphqlApiUrl={GRAPHQL_API_URL ?? ""}
                        fanIdBaseUrl={FAN_ID_BASE_URL ?? ""}
                        fanIdClientId={FAN_ID_CLIENT_ID ?? ""}
                        fanIdPreview={FAN_ID_PREVIEW}
                      >
                        <Layout
                          authState={authState}
                          isInitializing={isInitializing}
                          refetchProfile={refetchProfile}
                        >
                          <Suspense fallback={<Loader />}>
                            {isInitializing ? <Loader /> : <AppRoutes />}
                          </Suspense>
                        </Layout>
                      </ModalProvider>
                    </QueryProvider>
                  </ApiProvider>
                </AuthProvider>
              </ErrorBoundary>
            </DatadogProvider>
          </SplitFactoryProvider>
        </ThemeProvider>
      </BrowserRouter>
      <Zendesk defer zendeskKey={ZENDESK_KEY ?? ""} />
    </StrictMode>
  );
};
