import { Flex, FlexProps, Spinner, Text, TextProps } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { Trans, useTranslation } from "react-i18next";

import styles from "@/legacy/styles";
import { formatMonetaryValue } from "@/utils";

export enum EConfirmSectionRecordType {
  Default = "default",
  Total = "total",
  TotalDue = "total-due",
}

interface IRecordConfig {
  containerMargin: FlexProps["my"];
  mainTextStyles: TextProps;
  cashTextStyles?: TextProps;
}

const recordConfigMap: Record<EConfirmSectionRecordType, IRecordConfig> = {
  [EConfirmSectionRecordType.Default]: {
    containerMargin: "2px",
    mainTextStyles: { variant: ETextVariant.BaseParagraph },
  },
  [EConfirmSectionRecordType.Total]: {
    containerMargin: 0,
    mainTextStyles: {
      mt: "8px",
      pt: "8px",
      color: EColor.Neutral45,
      variant: ETextVariant.BaseParagraph,
      textTransform: "capitalize",
    },
    cashTextStyles: {
      mt: "8px",
      pt: "8px",
      pl: "18px",
      color: EColor.BrandWarning,
      variant: ETextVariant.BaseParagraph,
      textDecoration: "line-through",
      borderColor: EColor.Neutral15,
      borderTopWidth: "1px",
    },
  },
  [EConfirmSectionRecordType.TotalDue]: {
    containerMargin: { base: 0, md: "10px" },
    mainTextStyles: {
      ...styles.h2,
      variant: { base: ETextVariant.LG, md: ETextVariant.XL },
      mb: 0,
    },
    cashTextStyles: {
      ...styles.h2,
      variant: { base: ETextVariant.LG, md: ETextVariant.XL },
      mb: 0,
    },
  },
};

interface IConfirmSectionRecordProps {
  text: string;
  value?: number;
  type?: EConfirmSectionRecordType;
  extraText?: string;
  replaceZeroValue?: boolean;
  isLoading?: boolean;
  valueColor?: string;
}

export const ConfirmSectionRecord = (props: IConfirmSectionRecordProps) => {
  const {
    text,
    value,
    type = EConfirmSectionRecordType.Default,
    extraText,
    replaceZeroValue,
    isLoading,
    valueColor,
  } = props;
  const { t } = useTranslation();

  const recordConfig = recordConfigMap[type];
  const { containerMargin, mainTextStyles } = recordConfig;

  return (
    <Flex
      gap="8px"
      flexDirection="row"
      justify="space-between"
      align={extraText ? "start" : "center"}
      my={containerMargin}
    >
      <Text {...mainTextStyles}>{text}</Text>
      {!isLoading ? (
        <Flex direction="column" align="end">
          <Text
            variant={ETextVariant.Paragraph6}
            {...recordConfig?.cashTextStyles}
            {...(valueColor && { color: valueColor })}
          >
            {value !== undefined ? (
              value === 0 && replaceZeroValue ? (
                t("common.free")
              ) : (
                formatMonetaryValue(value)
              )
            ) : (
              <Text as="span" color={EColor.BrandDanger}>
                {t("common.invalid")}
              </Text>
            )}
          </Text>
          {extraText && (
            <Text
              variant={ETextVariant.Heading7}
              fontWeight="400"
              color={EColor.BrandCorduroy}
              textAlign="right"
              mt="4px"
              mb="8px"
            >
              <Trans>{extraText}</Trans>
            </Text>
          )}
        </Flex>
      ) : (
        <Spinner size="sm" />
      )}
    </Flex>
  );
};
