import { Box, HStack, Text, useUpdateEffect, VStack } from "@chakra-ui/react";
import {
  createFormElements,
  Button,
  EColor,
  ETextVariant,
  EButtonVariant,
  useIsMobile,
  states,
  countries,
} from "@fanatics-live/common-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { FieldPathByValue, useForm, useWatch } from "react-hook-form";

import {
  CollectAlternatingShippingAddress,
  CollectShippingAddress,
} from "@/gql";
import styles from "@/legacy/styles";

import {
  CountryUSAOption,
  createShippingSchema,
  validShippingAddress,
} from "./validations";

export interface IAlternateAddressFormProps {
  fulfillmentChargesLoading: boolean;
  fulfillmentChargesErrorMessage?: string;
  onSubmit: (alternateAddress: CollectAlternatingShippingAddress) => void;
  onCancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { Form, FormSelect, FormTextInput } = createFormElements<any>(); // TODO

export const AlternateAddressForm = ({
  fulfillmentChargesLoading,
  fulfillmentChargesErrorMessage,
  onSubmit,
  onCancel,
}: IAlternateAddressFormProps) => {
  const isMobile = useIsMobile();

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(createShippingSchema()),
  });

  const {
    control,
    formState: { isValid, errors },
    reset,
    setValue,
  } = methods;

  const addressData = useWatch({ control });
  const { countryId, state } = addressData;

  useUpdateEffect(() => {
    countryId !== CountryUSAOption.id && setValue("state", "");
  }, [countryId, setValue]);

  const submit = () => isValid && onSubmit(validShippingAddress(addressData));

  const cancel = () => {
    onCancel();
    reset();
  };

  return (
    <Form onSubmit={submit} {...methods}>
      <VStack
        spacing="16px"
        maxWidth={{ base: "420px", md: "327px" }}
        p={{ base: 0, md: "8px 0" }}
      >
        <FormTextInput
          variant="brand"
          name="name"
          placeholder={t("cart.shipping.alternate.name")}
          data-testid="address-form-name-input"
          {...styles.formInput}
        />
        <FormSelect
          variant="brand"
          name="countryId"
          options={countries.map(({ id, label }) => ({
            label,
            value: id,
          }))}
          placeholder={t("cart.shipping.alternate.country")}
          data-testid="address-form-country-select"
          {...styles.formInput}
          {...(countryId && { borderColor: EColor.BrandDark })}
        />
        <FormTextInput
          variant="brand"
          name="street1"
          placeholder={t("cart.shipping.alternate.address_1", { num: 1 })}
          data-testid="address-form-street1-input"
          {...styles.formInput}
        />
        <FormTextInput
          variant="brand"
          name="street2"
          placeholder={t("cart.shipping.alternate.address_2", { num: 2 })}
          data-testid="address-form-street2-input"
          {...styles.formInput}
        />
        <FormTextInput
          variant="brand"
          name="city"
          placeholder={t("cart.shipping.alternate.city")}
          data-testid="address-form-city-input"
          {...styles.formInput}
        />

        <HStack
          alignItems="flex-start"
          spacing="16px"
          sx={{
            "& label": {
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
            "input[aria-invalid=true]:focus+label": { width: "100%" },
            'input[aria-invalid=true]:not([value=""])+label': { width: "100%" },
          }}
        >
          <Box flex={1}>
            {countryId == CountryUSAOption.id ? (
              <FormSelect
                variant="brand"
                name={
                  "state" as FieldPathByValue<CollectShippingAddress, string>
                }
                options={states}
                placeholder={t("cart.shipping.alternate.state")}
                data-testid={"address-form-state-select"}
                {...styles.formInput}
                {...(state && { borderColor: EColor.BrandDark })}
                minW="50%"
              />
            ) : (
              <FormTextInput
                variant="brand"
                name="state"
                placeholder={t("cart.shipping.alternate.province")}
                data-testid={"address-form-state-input"}
                {...styles.formInput}
                {...(state && { borderColor: EColor.BrandDark })}
                minW="50%"
              />
            )}
          </Box>
          <Box flex={1}>
            <FormTextInput
              variant="brand"
              name="zip"
              placeholder={t("cart.shipping.alternate.zip")}
              data-testid="address-form-zip-input"
              {...styles.formInput}
              minW="50%"
            />
          </Box>
        </HStack>

        {!errors.zip &&
          !errors.state &&
          !errors.street1 &&
          !errors.countryId &&
          !fulfillmentChargesLoading &&
          !!fulfillmentChargesErrorMessage && (
            <Text
              variant={ETextVariant.Paragraph4}
              fontWeight="400"
              textTransform="uppercase"
              color={EColor.BrandDanger}
            >
              {fulfillmentChargesErrorMessage}
            </Text>
          )}

        <HStack spacing="15px" mt="8px" w="100%">
          <Button
            flex={1}
            isDisabled={!isValid}
            type="submit"
            variant={EButtonVariant.BRAND_PRIMARY}
            inverted={true}
            fontWeight="700"
            borderWidth="1px"
          >
            {t("btn.save")}
          </Button>
          {isMobile || (
            <Button
              flex={1}
              isDisabled={false}
              type="button"
              variant={EButtonVariant.BRAND_PRIMARY}
              borderWidth="1px"
              fontWeight="700"
              onClick={cancel}
            >
              {t("btn.cancel")}
            </Button>
          )}
        </HStack>
      </VStack>
    </Form>
  );
};
