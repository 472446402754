import { clsx, ClassValue } from "clsx";
import { ComponentType, lazy } from "react";
import { twMerge } from "tailwind-merge";

import { MONOLITH_URL } from "@/env";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const getFaviconPath = () => {
  if (process.env.NODE_ENV === "development") {
    return "/favicon-dev.png";
  }
  if (MONOLITH_URL.includes("dev")) {
    return "/favicon-dev.png";
  }
  if (MONOLITH_URL.includes("qa")) {
    return "/favicon-qa.png";
  }
  if (MONOLITH_URL.includes("uat")) {
    return "/favicon-uat.png";
  }
  return "/favicon.ico";
};

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport<
  T extends ComponentType,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}
