import { Box, Text } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { useTranslation } from "react-i18next";

export const WireContent = () => {
  const { t } = useTranslation();

  return (
    <Box pb="5px" pt="16px" pl={{ base: 0, md: "52px" }} pr="24px">
      <Text
        variant={ETextVariant.Paragraph6}
        fontWeight="500"
        color={EColor.BrandCorduroy}
        pb="20px"
      >
        {t("cart.payment.wire.pre_invoice_description")}
      </Text>
    </Box>
  );
};
