import { Modal, ModalContent } from "@chakra-ui/react";
import { useIsMobile } from "@fanatics-live/common-components";
import { ReactNode, useEffect, useRef, useState } from "react";

interface ICheckoutModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}

export const CheckoutModal = ({
  children,
  isOpen,
  onClose = () => {},
}: ICheckoutModalProps) => {
  const [isKeyboardOpened, setIsKeyboardOpened] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const visualViewportHeightRef = useRef(visualViewport?.height ?? 0);

  useEffect(() => {
    if (isMobile && visualViewport) {
      const resizeHandler = () => {
        setIsKeyboardOpened(
          (visualViewport?.height ?? 0) < visualViewportHeightRef.current,
        );
      };

      visualViewport?.addEventListener("resize", resizeHandler);

      return () => {
        visualViewport?.removeEventListener("resize", resizeHandler);
      };
    }
  }, [isMobile]);

  return (
    <Modal
      isOpen={isOpen}
      size="full"
      blockScrollOnMount={!isKeyboardOpened}
      onClose={onClose}
    >
      <ModalContent>{children}</ModalContent>
    </Modal>
  );
};
