import { Icon, IconProps } from "@chakra-ui/react";
import { EColor } from "@fanatics-live/common-components";

export const CreditCardIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 22 16" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
      >
        <path
          d="M21 5V13C21 14.1046 20.1046 15 19 15H3C1.89543 15 1 14.1046 1 13V3C1 1.89543 1.89543 1 3 1H19C20.1046 1 21 1.89543 21 3V5ZM21 5H5"
          stroke={EColor.Black}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
