export const InfoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="info">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12.679 6.7556C13.0895 7.12506 13.1228 7.75735 12.7533 8.16786L12.7433 8.17897C12.3738 8.58948 11.7415 8.62275 11.331 8.25329C10.9205 7.88383 10.8872 7.25154 11.2567 6.84103L11.2667 6.82992C11.6362 6.41941 12.2685 6.38614 12.679 6.7556ZM12 10.5C12.5523 10.5 13 10.9477 13 11.5V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V11.5C11 10.9477 11.4477 10.5 12 10.5Z"
        fill="#0C4599"
      />
    </g>
  </svg>
);
