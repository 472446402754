import {
  EButtonVariant,
  EColor,
  ETextVariant,
} from "@fanatics-live/common-components";

export default {
  sectionBox: {
    borderTop: { base: "none", md: "1px" },
    borderRight: { base: "none", md: "1px" },
    borderBottom: { base: "1px", md: "1px" },
    borderLeft: { base: "none", md: "1px" },
    borderColor: { base: EColor.Neutral15, md: EColor.Neutral15 },
    borderRadius: { base: 0, md: "8px" },
    w: "100%",
    pt: { base: "30px", md: "24px" },
    pb: { base: "30px", md: "24px" },
    pr: { base: "16px", md: "24px" },
    pl: { base: "16px", md: "24px" },
    mb: { base: 0, md: "20px" },
  },
  h2: {
    variant: ETextVariant.XL,
    letterSpacing: "-0.03em",
    mb: "14px",
  },
  h3: {
    variant: ETextVariant.BaseBold,
    color: EColor.Black,
    mb: "4px",
  },
  p: {
    variant: ETextVariant.BaseParagraph,
    color: EColor.Neutral65,
  },
  small: {
    variant: ETextVariant.Paragraph2,
    color: EColor.BrandCorduroy,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  twoLineEllipses: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    sx: {
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "2" /* number of lines to show */,
    },
    lineHeight: "20px" /* fallback */,
    maxHeight: "40px" /* fallback */,
  },
  editButton: {
    variant: EButtonVariant.BRAND_SECONDARY,
    size: "sm",
    lineHeight: "16px",
    pt: "14px",
    px: "12px",
  },
  badge: {
    borderRadius: 0,
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "15px",
    textTransform: "capitalize",
    px: "4px",
    pt: "1px",
  },
  banner: {
    background: "#DDEAFD",
    color: "#0C4599",
    padding: "12px",
  },
  selectLink: {
    variant: ETextVariant.Small,
    color: EColor.Blue50,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    textDecor: "underline",
    mt: "8px",
    _hover: { color: EColor.Blue100 },
  },
  formInput: {
    flex: 1,
    fontWeight: 500,
    height: "40px",
    minW: "200px",
  },
};
