import { X } from "lucide-react";
import { useState } from "react";

import {
  Alert as ShadcnAlert,
  AlertDescription,
  AlertTitle,
} from "@/components/shadcn/alert";

import { cn } from "../utils";

import { Button } from "./shadcn/button";

export const Alert = ({
  className,
  description,
  title,
}: {
  className?: string;
  description?: string;
  title?: string;
}) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <ShadcnAlert className={cn("mb-5 mt-0", className)}>
        <div className="flex justify-between">
          <AlertTitle className="text-small-bold font-bold">{title}</AlertTitle>
          <Button
            variant="link"
            size="icon"
            className="size-3"
            onClick={() => setVisible(false)}
          >
            <X />
          </Button>
        </div>
        <AlertDescription className="text-xs">{description}</AlertDescription>
      </ShadcnAlert>
    )
  );
};
