import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { useTranslation } from "react-i18next";

import { WalletIcon } from "@/components";
import { formatMonetaryValue } from "@/utils";

export const AccountFunds = ({
  accountFunds,
  accountFundsToUse,
  ...restProps
}: {
  accountFunds: number;
  accountFundsToUse: number;
} & FlexProps) => {
  const { t } = useTranslation();

  return accountFundsToUse ? (
    <Flex
      alignItems="center"
      gap="8px"
      py="14px"
      borderBottom={{ base: "none", md: `1px solid ${EColor.Neutral15}` }}
      gridArea="funds"
      {...restProps}
    >
      <WalletIcon width="36px" height="24px" />
      <Box>
        <Text
          variant={ETextVariant.BaseBold}
          color={EColor.Black}
          fontWeight={600}
        >
          {t("cart.payment.account_funds.title")}
        </Text>
        <Flex gap="8px">
          <Text variant={ETextVariant.Small} color={EColor.Black}>
            {t("cart.payment.account_funds.balance", {
              amount: formatMonetaryValue(accountFunds),
            })}
          </Text>
          <Text variant={ETextVariant.Small} color={EColor.Neutral65}>
            {t("cart.payment.account_funds.applied", {
              amount: formatMonetaryValue(accountFundsToUse),
            })}
          </Text>
        </Flex>
      </Box>
    </Flex>
  ) : null;
};
