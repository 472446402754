import { useStripe } from "@stripe/react-stripe-js";
import {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripeError,
} from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CollectValidPaymentMethod } from "@/gql";
import { useCheckoutDetails, useCartContext } from "@/hooks";
import { getBalanceRemaining } from "@/utils";

// Apple and Google Pay require a Stripe PaymentRequest. This creates one and,
// if one of the methods can be used on the current platform, adds it to the
// cartContext so that the button can be shown as an option. Later this same
// PaymentRequest should be passed to useConfirmPayment if one of these payment
// methods was chosen.
export const useCreateStripePaymentRequest = () => {
  const stripe = useStripe();
  const { detailsOptions, paymentMethod, setPaymentRequestMethods } =
    useCartContext();
  const { details } = useCheckoutDetails(detailsOptions);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  const balanceRemaining =
    getBalanceRemaining(
      paymentMethod,
      details?.balanceDue,
      details?.potentialBalanceDueWithCreditCardFee,
    )?.amountInCents ?? 0;

  useEffect(() => {
    if (stripe) {
      if (paymentRequest && !paymentRequest.isShowing()) {
        paymentRequest.update({
          total: { label: "Total", amount: balanceRemaining },
        });
      } else {
        const pr = stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Total",
            amount: balanceRemaining,
          },
          requestPayerName: true,
          requestPayerEmail: true,
          disableWallets: ["link", "browserCard"],
        });

        pr.canMakePayment().then((result) => {
          // Requires https
          if (result) {
            setPaymentRequestMethods(result);
            setPaymentRequest(pr);
          }
        });
      }
    }
  }, [
    balanceRemaining,
    details,
    paymentMethod,
    paymentRequest,
    setPaymentRequestMethods,
    stripe,
  ]);

  return paymentRequest;
};

// Apple and Google Pay need to have a PaymentMethod created to send
// along with final payment confimation. Stripe uses a
// 'paymentmethod' event listener (triggered on click with
// `paymentRequest.open`) with an async callback to accomplish this.
//
// Details:
// * https://docs.stripe.com/stripe-js/elements/payment-request-button?client=react
// * https://docs.stripe.com/js/payment_request/events/on_paymentmethod
// * https://github.com/stripe/react-stripe-js/issues/126
export const useConfirmStripePaymentRequest = () => {
  const { t } = useTranslation();
  const stripe = useStripe();

  return (
    clientSecret: string,
    paymentMethods: CollectValidPaymentMethod[],
    paymentRequest: PaymentRequest,
  ): Promise<Record<string, PaymentIntent | boolean | Error | StripeError>> => {
    return new Promise((resolve) => {
      if (!stripe || !paymentRequest) {
        resolve({
          error: new Error('"stripe" or "paymentRequest" are not provided'),
        });
      }

      const handleCancel = () => {
        resolve({ canceled: true });
      };

      const handlePaymentMethod = async (
        event: PaymentRequestPaymentMethodEvent,
      ) => {
        const { paymentIntent, error: confirmCardPaymentError1 } =
          await stripe!.confirmCardPayment(
            clientSecret,
            { payment_method: event.paymentMethod.id },
            { handleActions: false },
          );

        if (confirmCardPaymentError1) {
          event.complete("fail");
          resolve({
            error: confirmCardPaymentError1,
          });
        } else {
          event.complete("success");

          if (paymentIntent.status === "requires_action") {
            const { error: confirmCardPaymentError2 } =
              await stripe!.confirmCardPayment(clientSecret);

            if (
              confirmCardPaymentError2 ||
              paymentIntent.status === "requires_action"
            ) {
              const message = t("alert.content.review_stripe_info", {
                paymentType: paymentMethods[paymentMethods.length - 1],
              });

              resolve({
                error: new Error(message),
              });
            }
          }

          resolve({
            paymentIntent,
          });
        }
      };

      paymentRequest
        .off("cancel")
        .off("paymentmethod")
        .on("cancel", handleCancel)
        .on("paymentmethod", handlePaymentMethod);
    });
  };
};
