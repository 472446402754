import { ApiClient, Auth } from "@fanatics-live/common-components";

import { API_BASE_URL, AUTH_BASE_URL, MOBILE_BASE_URL } from "@/env";

export const auth = new Auth(AUTH_BASE_URL, MOBILE_BASE_URL, "v2");
export const apiClient = new ApiClient(auth, API_BASE_URL);

if (process.env.NODE_ENV === "development") {
  const userId = process.env.REACT_APP_USER_ID;
  if (userId) {
    apiClient.service.defaults.headers.common.User = userId;
  }
}
