import numeral from "numeral";

import { Optional } from "@/utils";

export enum EDateTimeToken {
  NumericDate = "LL/dd/yyyy",
}

export enum EMonetaryToken {
  Precise = "$0,0.00",
  Short = "$0,0",
}

export const formatMonetaryValue = (
  value: number,
  token: EMonetaryToken = EMonetaryToken.Precise,
) => {
  return numeral(value).format(token);
};

export const joinOptionalStrings = (
  separator: string,
  ...strings: Array<Optional<string>>
): string => {
  return strings.filter(Boolean).join(separator);
};
