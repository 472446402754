import { Center, Text, CenterProps } from "@chakra-ui/react";
import { EColor } from "@fanatics-live/common-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const InfoMessage = ({
  singleInvoice = false,
  ...restProps
}: {
  singleInvoice?: boolean;
} & CenterProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { message, color } =
    useMemo(() => {
      if (searchParams.get("info") === "payment-required") {
        return {
          message: singleInvoice
            ? t("info_message.unpaid_invoice")
            : t("info_message.unpaid_invoices"),
          color: EColor.LightBlue,
        };
      }
      if (searchParams.get("danger") === "force-payment") {
        return {
          message: t("danger_message.force_payment"),
          color: EColor.BrandDanger,
        };
      }
    }, [searchParams, singleInvoice, t]) || {};

  return (
    <>
      {message && (
        <Center
          maxW="860px"
          bgColor={color}
          borderRadius="6px"
          my="24px"
          mx="12px"
          p="12px"
          {...restProps}
        >
          <Text color={EColor.White} textAlign="center">
            {message}
          </Text>
        </Center>
      )}
    </>
  );
};
