import { useQuery } from "@apollo/client";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import { EColor, EPaymentType } from "@fanatics-live/common-components";
import { useTranslation } from "react-i18next";

import { collectInvoice, CollectShippingType } from "@/gql";
import { useCheckoutDetails, useCartContext } from "@/hooks";
import { getBalanceRemaining, hasCreditCardFee, toDollars } from "@/utils";

import {
  EConfirmSectionRecordType,
  ConfirmSectionRecord,
} from "./ConfirmSectionRecord";

export interface IConfirmSectionPayment {
  text: string;
  amount: number;
  type: EPaymentType;
}

export const ConfirmSectionContent = () => {
  const { t } = useTranslation();
  const { paymentMethod, detailsOptions } = useCartContext();
  const { details, loading: detailsLoading } =
    useCheckoutDetails(detailsOptions);
  const { data, loading: invoiceLoading } = useQuery(collectInvoice, {
    skip: !detailsOptions.invoiceId,
    variables: { collectInvoiceId: detailsOptions.invoiceId },
  });

  const buyersPremium = toDollars(data?.collectInvoice?.buyersPremium);
  const amountPaid = toDollars(details?.amountPaid);
  const shippingAmount = toDollars(details?.shipping);
  const taxAmount = toDollars(details?.tax);
  const creditCardFee =
    hasCreditCardFee(paymentMethod) &&
    toDollars(details?.potentialCreditCardFee);
  const subTotalAmount = toDollars(details?.subTotal) - buyersPremium;
  const totalDue = toDollars(
    hasCreditCardFee(paymentMethod)
      ? details?.potentialTotalWithCreditCardFee
      : details?.total,
  );
  const balanceRemainingAmount = toDollars(
    getBalanceRemaining(
      paymentMethod,
      details?.balanceDue,
      details?.potentialBalanceDueWithCreditCardFee,
    ),
  );

  return detailsLoading || invoiceLoading ? (
    <Box textAlign="center" pt="5px">
      <Spinner />
    </Box>
  ) : (
    <Flex direction="column" w="100%" maxW={{ base: "100%", md: "440px" }}>
      <ConfirmSectionRecord
        text={t("cart.confirm.subtotal")}
        value={subTotalAmount}
      />

      {!!buyersPremium && (
        <ConfirmSectionRecord
          text={t("cart.confirm.buyers_premium")}
          value={buyersPremium}
        />
      )}

      {!!shippingAmount && (
        <ConfirmSectionRecord
          text={
            details?.shippingType === CollectShippingType.InPerson
              ? t("order_summary.handling")
              : t("order_summary.shipping")
          }
          value={shippingAmount}
          replaceZeroValue
        />
      )}

      {!!taxAmount && (
        <ConfirmSectionRecord
          text={t("cart.confirm.tax")}
          value={taxAmount}
          replaceZeroValue
        />
      )}

      {!!creditCardFee && (
        <ConfirmSectionRecord
          text={t("order_summary.credit_card_fee")}
          value={creditCardFee}
        />
      )}

      {balanceRemainingAmount < totalDue && (
        <ConfirmSectionRecord
          text={t("cart.confirm.invoice_total")}
          type={EConfirmSectionRecordType.Total}
          value={totalDue}
        />
      )}

      {details?.payments?.map(
        (payment) =>
          payment && (
            <ConfirmSectionRecord
              key={payment.paymentMethod}
              text={payment.paymentMethod ?? "UNKNOWN"}
              value={-toDollars(payment.amount)}
            />
          ),
      )}

      {balanceRemainingAmount !== undefined && (
        <Box
          borderColor={EColor.Neutral15}
          borderTopWidth="1px"
          mt="4px"
          mb={{ base: "4px", md: "-8px" }}
          pt={{ base: "18px", md: "8px" }}
        >
          <ConfirmSectionRecord
            text={
              amountPaid
                ? t("cart.confirm.amount_paid")
                : t("cart.confirm.balance_remaining")
            }
            value={amountPaid || balanceRemainingAmount}
            type={EConfirmSectionRecordType.TotalDue}
          />
        </Box>
      )}
    </Flex>
  );
};
