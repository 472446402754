import { useMutation } from "@apollo/client";
import { t } from "i18next";

import {
  collectCheckoutPaymentLock,
  CollectCheckoutPaymentLockInput,
} from "@/gql";
import { useCartContext } from "@/hooks";

// Creates an Invoice and Stripe PaymentIntent. If the invoice is not paid,
// partially paid or pending, it will be deleted in 5 minutes.
export const useCreatePayment = () => {
  const { detailsOptions, setDetailsOptions, setGlobalErrorMessage } =
    useCartContext();
  const [lockPayment] = useMutation(collectCheckoutPaymentLock);

  return async (input: CollectCheckoutPaymentLockInput) => {
    const { data: lockData, errors: lockErrors } = await lockPayment({
      variables: { input },
    });

    if (lockErrors?.length) {
      throw new Error("Payment lock error: " + lockErrors[0].message);
    }

    const { result, messages: lockValidationMessages } =
      lockData?.collectCheckoutPaymentLock || {};

    if (lockValidationMessages?.length) {
      const message = lockValidationMessages[0]?.message;

      if (message === "This action is unauthorized.") {
        // An invoice already exists for the listing
        setGlobalErrorMessage(t("cart.error.unavailable"));
      } else {
        throw new Error("Payment lock validation error: " + message);
      }
    }

    const { clientSecret, invoiceId, paymentIntentId } = result || {};

    if (!invoiceId) {
      throw new Error('"invoiceId" required to confirm payment method');
    } else if (!detailsOptions.invoiceId) {
      // If an invoiceId was returned, the listing was locked. Use the invoice
      // for all remaining requests (i.e. if the card is declined and payment
      // must be retried).
      setDetailsOptions({ ...detailsOptions, invoiceId });
    }

    return { clientSecret, invoiceId, paymentIntentId };
  };
};
