import { countries } from "@fanatics-live/common-components";
import { t } from "i18next";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { number, object, ObjectSchema, string } from "yup";

import {
  CollectAlternatingShippingAddress,
  CollectShippingAddress,
} from "@/gql";
import { Nullable } from "@/utils";

interface IZipSchema {
  zip: string;
}

interface IPhoneSchema {
  phone: Nullable<string>;
}

interface IValidationSchema {
  Zip: ObjectSchema<IZipSchema>;
  Phone: ObjectSchema<IPhoneSchema>;
}

export const CountryUSAOption = countries[0];

const validateZip = (countryId?: number | string, zip?: string): boolean => {
  const country = countries.find(
    (country) => country.id === Number(countryId),
  )?.value;
  if (country && zip) {
    return postcodeValidatorExistsForCountry(country)
      ? postcodeValidator(zip, country)
      : true;
  }
  return false;
};

const createValidationSchema = (): IValidationSchema => ({
  Zip: object({
    zip: string()
      .trim()
      .required(t("validation_errors.zip_required"))
      .test({
        name: "zip",
        test: (value, ctx) => {
          return validateZip(ctx.parent.countryId, value);
        },
        message: t("validation_errors.zip_invalid"),
      }),
  }),
  Phone: object({
    phone: string()
      .nullable()
      .trim()
      .required(t("validation_errors.phone_required"))
      .test({
        name: "phone",
        test: (text) => isValidPhoneNumber(text ?? "", "US"),
        message: t("validation_errors.phone_invalid"),
      }),
  }),
});

export const createShippingSchema = () => {
  const { Zip } = createValidationSchema();

  return object({
    name: string().trim().required(t("validation_errors.name_required")),
    street1: string().trim().required(t("validation_errors.street_required")),
    street2: string().trim().optional(),
    city: string().trim().required(t("validation_errors.city_required")),
    state: string().when("countryId", {
      is: CountryUSAOption.id,
      then: () => string().required(t("validation_errors.state_required")),
      otherwise: () =>
        string().trim().required(t("validation_errors.region_required")),
    }),
    countryId: number()
      // NOTE: used for placeholder selection, when `value` equals `''` and gain `NaN` after cast to number
      .typeError(t("validation_errors.country_required"))
      .required(t("validation_errors.country_required")),
  }).concat(Zip);
};

export const validShippingAddress = (address: CollectShippingAddress) => {
  const validAddress: CollectAlternatingShippingAddress = {
    city: address.city,
    country: "" + address.countryId,
    name: address.name,
    state: address.state,
    addressLine1: address.street1,
    addressLine2: address.street2,
    zip: address.zip,
  };
  return validAddress;
};
